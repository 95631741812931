@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
*,
*::before,
*::after {
  box-sizing: inherit;
}

.menu-icon {
  height: 30px;
  width: 30px;
  position: fixed;
  z-index: 2;
  left: 50px;
  top: 40px;
  cursor: pointer;
}
.menu-icon__line {
  height: 2px;
  width: 30px;
  display: block;
  background-color: black;
  margin-bottom: 4px;
  transition: transform 0.2s ease, background-color 0.5s ease;
}
.menu-icon__line-left {
  width: 15px;
}
.menu-icon__line-right {
  width: 15px;
  float: right;
}
.menu-icon .active__line {
  background-color: #000;
  transform: translateX(0px) rotate(-45deg);
}
.menu-icon .active__line-left {
  transform: translateX(1px) rotate(45deg);
}
.menu-icon .active__line-right {
  transform: translateX(-2px) rotate(45deg);
}

.nav {
  position: fixed;
  z-index: 1;
  visibility: hidden;
}
.nav.open {
  visibility: visible;
}
.nav.open:before, .nav.open:after {
  transform: translateX(0%) translateY(0%);
}
.nav.open:after {
  transition-delay: 0.1s;
}
.nav.open:before {
  transition-delay: 0s;
}
.nav.open .nav--list-item {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 1.3s ease, transform 1.3s ease, color 1.3s ease;
}
.nav.open .nav--list-item:nth-child(0) {
  transition-delay: 0.9s;
  text-decoration: none;
  color: black;
}
.nav.open .nav--list-item:nth-child(1) {
  transition-delay: 1s;
  text-decoration: none;
  color: black;
}
.nav.open .nav--list-item:nth-child(2) {
  transition-delay: 1.1s;
  text-decoration: none;
  color: black;
}
.nav.open .nav--list-item:nth-child(3) {
  transition-delay: 1.2s;
  text-decoration: none;
  color: black;
}
.nav.open .nav--list-item:nth-child(4) {
  transition-delay: 1.3s;
  text-decoration: none;
  color: black;
}
.nav.open .social-links {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
}
.nav.open .social-links:nth-child(0) {
  transition-delay: 0.5s;
  text-decoration: none;
  color: black;
}
.nav.open .social-links:nth-child(1) {
  transition-delay: 0.6s;
  text-decoration: none;
  color: black;
}
.nav.open .desc_link_item {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 1.3s ease, transform 1.3s ease, color 1.3s ease;
}
.nav.open .desc_link_item:nth-child(0) {
  transition-delay: 0.9s;
  text-decoration: none;
  color: black;
}
.nav.open .desc_link_item:nth-child(1) {
  transition-delay: 1s;
  text-decoration: none;
  color: black;
}
.nav.open .desc_link_item:nth-child(2) {
  transition-delay: 1.1s;
  text-decoration: none;
  color: black;
}
.nav.open .desc_link_item:nth-child(3) {
  transition-delay: 1.2s;
  text-decoration: none;
  color: black;
}
.nav.open .desc_link_item:nth-child(4) {
  transition-delay: 1.3s;
  text-decoration: none;
  color: black;
}
.nav:before, .nav:after {
  content: "";
  position: fixed;
  width: 97vw;
  height: 100vh;
  background: rgba(234, 234, 234, 0.2);
  z-index: -1;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
  transform: translateX(0%) translateY(-110%);
}
.nav:after {
  background: var(--background-color);
  transition-delay: 0s;
}
.nav:before {
  transition-delay: 0.1s;
}
.nav__content {
  position: fixed;
  top: 50%;
  transform: translate(0%, -50%);
  width: 100%;
  text-align: center;
  font-size: calc(2vw + 10px);
  font-weight: 200;
  cursor: pointer;
}
.nav .nav--list-item {
  position: relative;
  display: inline-block;
  transition-delay: 0.8s;
  opacity: 0;
  transform: translate(0%, 100%);
  transition: opacity 0.2s ease, transform 0.3s ease;
  margin-right: 25px;
}
.nav .nav--list-item:before {
  content: "";
  position: absolute;
  background: #000000;
  width: 20px;
  height: 1px;
  top: 100%;
  transform: translate(0%, 0%);
  transition: all 0.3s ease;
  z-index: -1;
}
.nav .nav--list-item:hover:before {
  width: 100%;
}

.nav--list-item {
  position: relative;
  display: inline-block;
  transition-delay: 0.8s;
  opacity: 0;
  font-size: 3rem;
  font-weight: 700;
  font-family: "eurostile", sans-serif;
  transform: translate(0%, 100%);
  color: #070d55;
  transition: opacity 0.2s ease, transform 0.3s ease;
  margin-right: 25px;
}
.nav--list-item:before {
  content: "";
  position: absolute;
  background: #000000;
  width: 20px;
  height: 1.5px;
  top: 100%;
  transform: translate(0%, 0%);
  transition: all 0.3s ease;
  z-index: -1;
}
.nav--list-item:hover:before {
  width: 100%;
}

.social-links {
  position: relative;
  text-align: left;
  bottom: 5rem;
  left: 2rem;
  margin: 0 0 30px;
  padding-left: 100px;
  z-index: 1;
  list-style: none;
  opacity: 0;
  transform: translate(0%, 100%);
  transition: opacity 0.2s ease, transform 0.3s ease;
}

.social-links svg {
  font-size: 2rem;
}

.social-links::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 80px;
  background-color: #7f00e0;
  top: 63%;
  left: 0;
}

.social-links li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 50px 0 0;
}

.social-links li svg {
  color: black;
}

.social-links li svg:hover {
  color: white;
}

.nav__list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: flex-start;
  padding: 2rem;
}

@media screen and (max-width: 512px) {
  .nav--list-item {
    font-size: 2.5rem !important;
  }
  .nav::after {
    width: 89vw !important;
  }
  .social-links {
    bottom: 0rem !important;
  }
  .social-links li svg {
    width: 1em !important;
    height: 1em !important;
  }
}
@media screen and (max-width: 512px) {
  .desc_link_item {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .nav--list-item {
    font-size: 5rem;
  }
  .nav::after {
    width: 96vw;
  }
  .social-links li svg {
    height: 4rem;
    width: 10rem;
  }
}
.nav__list_desc {
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 40rem;
}

.desc_link_item {
  font-size: 1.2rem;
  width: 80%;
  opacity: 0;
  transform: translate(0%, 100%);
  transition: opacity 0.2s ease, transform 0.3s ease;
}/*# sourceMappingURL=NavBar.css.map */