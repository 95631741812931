@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

$width: 20px;
$height: 20px;
$bounce_height: 30px;
$bgcolor: #fbd33c;
$themecolor: black;

.wrap {
    width: 200px;
        height: 60px;
        position: absolute;
        margin: 20px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
}

.wrap-none {
    opacity: 0;
}

.circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: $themecolor;
    left: 15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }

    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 0%;
    }
}

.circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
}

.circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow {
    0% {
        transform: scaleX(1.5);
    }

    40% {
        transform: scaleX(1);
        opacity: .7;
    }

    100% {
        transform: scaleX(.2);
        opacity: .4;
    }
}

.shadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
}

.shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.mainPagebg {
    min-height: 100vh;
    position: relative;
    background-color: $bgcolor;
    z-index: 10;
    margin: 20px 20px;
}

.mainPg-none{
    display: none;
}
.text {
    position: absolute;
        top: 8rem;
        font-size: 20px;
        letter-spacing: 9px;
        color: $themecolor;
        width: 36rem;
        left: 0%;
    font-family: 'Montserrat', sans-serif;
}

.bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: $width;

    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        width: $width;
        height: $height;
        border-radius: 50%;
        background-color: $themecolor;
        transform-origin: 50%;
        animation: bounce 500ms alternate infinite ease;
    }
}

@keyframes bounce {
    0% {
        top: $bounce_height;
        height: 5px;
        border-radius: 60px 60px 20px 20px;
        transform: scaleX(2);
    }

    35% {
        height: $height;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 0;
    }
}

* {
    padding: 0;
    margin: 0;
}

html,
body {
    position: relative;
    width: 100%;
    height: 100%;
}



canvas {
    background: black;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


@media screen and (max-width: 480px) {
    .text{
        font-size: 10px !important;
        left: -40% !important;
    }

    .wrap{
        left: 44% !important;
    }
    
}