@import "../../styles/Variables/Variables.scss";


.alert-dialog h2,div.MuiDialogContent-root{
font-family: $content-fonts;
}

.alert-dialog h2{
    font-size: 2.5rem;
        font-weight: 400;
        color: red;
}

.alert-dialog h5 {
    font-family: $content-fonts;
        font-size: 1.05rem;
        
}

