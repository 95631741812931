@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
*,
*::before,
*::after {
  box-sizing: inherit;
}

.mainPage {
  min-height: 100vh;
  position: relative;
  font-family: "Bitter", serif;
  overflow-y: overlay;
}

.tag_top {
  top: 1rem;
  bottom: auto;
}

.tag_top::after {
  content: "<body>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -3rem;
  top: 2rem;
  position: absolute;
}

.tags {
  color: rgb(140, 137, 137);
  font-style: italic;
  font-weight: 500;
  left: 11rem;
  position: absolute;
  font-size: 1.3rem;
  font-family: "La Belle Aurore", cursive;
}

.scroll-down {
  position: relative;
  display: flex;
  justify-content: center;
  top: 24rem;
  color: white;
  font-family: "Bitter", serif;
}

.scroll-down > span {
  padding-right: 5px;
}

.scroll-down svg {
  animation: bounce2 1s ease infinite;
}

@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}/*# sourceMappingURL=MainPage.css.map */