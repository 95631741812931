@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
*,
*::before,
*::after {
  box-sizing: inherit;
}

.mainPage-content {
  position: relative;
  bottom: 58rem;
}

.mainPage-content > h2 {
  font-size: 3.8rem;
  position: relative;
  font-family: "eurostile", sans-serif;
  color: #070d55;
  text-align: justify;
  left: 6rem;
}

.mainPage-content > h2::before {
  content: "<h2>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  font-size: 1.25rem;
  bottom: 4rem;
  position: absolute;
}

.mainPage-content > h2::after {
  content: "</h2>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
}

.mainPage-content::after {
  content: "";
  position: absolute;
  left: 11%;
  top: 150%;
  width: 80rem;
  background: black;
  opacity: 0.435;
  height: 1px;
}

.main_dot {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background: #88cdec !important;
  bottom: 1.4rem;
  left: 20.5rem !important;
}

.more_links_dot {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background: #88cdec !important;
  bottom: 1.2rem;
  left: 57.8rem !important;
}

.main_underline {
  content: "";
  position: relative;
  left: 12rem;
  height: 4px;
  display: block;
  margin-top: 1px;
  width: 3.5rem;
  bottom: 0.5rem;
  background: #88cdec;
  left: 6rem;
  width: 10.5rem;
}

.mainPage-message {
  position: relative;
  display: flex;
  align-items: center;
  gap: 3rem;
  top: 6rem;
  left: 10%;
  line-height: 1.5;
  width: 80%;
  text-align: left;
}

.mainPage-message > * {
  font-size: 1.5rem;
  font-family: "eurostile", sans-serif;
  font-weight: 300;
  color: #070d55;
}

.mainPage-message svg {
  font-size: 3.5rem;
  animation-name: bouncer;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes bouncer {
  from {
    transform: translateX(-40px);
  }
  to {
    transform: translateX(20px);
  }
}
.mainPage-message > button {
  padding: 3px;
  text-align: center;
  width: 20rem;
}

.mainPage-message span:first-of-type {
  position: relative;
  margin: 10px;
  width: 50%;
  text-align: left;
}

.mainPage-message button {
  border-radius: 6px;
}

.mainPage-message span::before {
  content: "<p>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 8rem;
  font-size: 1.25rem;
  position: absolute;
  top: -2rem;
  left: -2rem;
}

.mainPage-message span::after {
  content: "</p>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 29rem;
  font-size: 1.25rem;
  position: absolute;
  top: 6.5rem;
  left: -2rem;
}

.home-page-button {
  display: flex;
  gap: 10rem;
  position: relative;
  align-items: center;
  top: 30rem;
  left: 20rem;
}

.home-page-button svg {
  font-size: 3.5rem;
}

.button-52 {
  font-size: 20px;
  font-weight: 200;
  height: 3rem;
  width: 20rem;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  font-family: "eurostile", sans-serif;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  background-color: #88cdec;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  z-index: 2;
}

.button-52::after {
  content: "";
  border: 5px solid black;
  border-radius: 10px;
  width: 98%;
  z-index: -1;
  position: absolute;
  height: 94%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.button-52:hover::after {
  top: 0px;
  left: 0px;
  background-color: rgb(193, 193, 193);
}

.more_links {
  position: relative;
  top: 25rem;
  right: 40rem;
}

.more_links::before {
  content: "<div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: -3rem;
  left: 44rem;
}

.more_links::after {
  content: "</div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 44rem;
  top: 4rem;
}

.more_links > div {
  font-size: 2.5rem;
  font-family: "eurostile", sans-serif;
  font-weight: bold;
  color: #070d55;
}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}
@media screen and (max-width: 512px) {
  .mainPage-content > h2 {
    font-size: 2rem !important;
    left: 3rem !important;
  }
  .mainPage-content > h2::before {
    font-size: 1rem !important;
    bottom: 2rem !important;
    left: -2rem !important;
  }
  .mainPage-content > h2::after {
    font-size: 1rem !important;
    top: 3rem !important;
    left: -2rem !important;
  }
  .mainPage-content {
    bottom: 40rem !important;
  }
  .main_underline {
    width: 4rem;
    left: 3rem !important;
  }
  .main_dot {
    left: 11rem !important;
    height: 7px !important;
    width: 7px !important;
    bottom: 0.6rem !important;
  }
  .mainPage-message {
    flex-direction: column !important;
    left: 0 !important;
    width: 100% !important;
  }
  .mainPage-message span:first-of-type {
    width: 70% !important;
  }
  .mainPage-message span::before {
    font-size: 1rem !important;
  }
  .mainPage-message span::after {
    font-size: 1rem !important;
  }
  .mainPage-message > * {
    font-size: 14px !important;
  }
  .button-52 {
    width: 5rem !important;
    height: 1rem !important;
    font-size: 14px !important;
  }
  .mainPage-message svg {
    display: none !important;
  }
  .mainPage-content::after {
    top: 92% !important;
    left: 5% !important;
    width: 18.5rem !important;
  }
  .more_links {
    top: 10rem !important;
    right: 6rem !important;
  }
  .more_links > div {
    font-size: 1.3rem !important;
  }
  .more_links_dot {
    height: 6px !important;
    width: 6px !important;
    left: 13.8rem !important;
    bottom: 10px !important;
  }
  .home-page-button {
    top: 13rem !important;
    left: 1rem !important;
    gap: 1.4rem !important;
  }
  .home-page-button svg {
    font-size: 1rem !important;
  }
}
@media screen and (max-width: 1000px) {
  .mainPage-message {
    flex-direction: column;
    gap: 7rem;
    width: 100%;
    left: 0;
  }
  .mainPage-content::after {
    top: 65%;
    left: 10%;
    width: 37.5rem;
  }
  .mainPage-message span:first-of-type {
    width: 70%;
  }
  .mainPage-message svg {
    display: none !important;
  }
  .more_links {
    top: 19rem;
    right: 14rem;
  }
  .more_links::before {
    left: 16rem;
  }
  .more_links::after {
    left: 16rem;
  }
  .more_links_dot {
    left: 30rem !important;
  }
  .home-page-button {
    flex-direction: column;
    left: 0;
  }
  .mainPage-message span::after {
    top: 8.5rem;
  }
}
@media screen and (max-width: 480px) {
  .mainPage-content {
    bottom: 53rem !important;
  }
  .mainPage-content::after {
    left: 12% !important;
  }
  .home-page-button {
    top: 16rem !important;
    left: 0 !important;
  }
  .more_links {
    top: 12rem !important;
    right: 7rem !important;
  }
  .more_links_dot {
    display: none !important;
  }
  .more_links::before {
    top: -2rem !important;
    left: 9rem !important;
    font-size: 1rem !important;
  }
  .more_links::after {
    left: 9rem !important;
    font-size: 1rem !important;
    top: 2rem !important;
  }
  .main_dot {
    left: 12rem !important;
    bottom: 0.7rem !important;
  }
  .button-52::after {
    display: none !important;
  }
}/*# sourceMappingURL=Main.css.map */