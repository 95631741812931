@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
.alert-dialog h2, div.MuiDialogContent-root {
  font-family: "eurostile", sans-serif;
}

.alert-dialog h2 {
  font-size: 2.5rem;
  font-weight: 400;
  color: red;
}

.alert-dialog h5 {
  font-family: "eurostile", sans-serif;
  font-size: 1.05rem;
}/*# sourceMappingURL=AlertDialog.css.map */