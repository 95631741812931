@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
*,
*::before,
*::after {
  box-sizing: inherit;
}

.button_main_contact {
  position: fixed;
  top: 39px;
  right: 0px;
  z-index: 1;
  text-align: justify;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_main_contact svg {
  width: 2rem;
  height: 2rem;
  color: var(--background-color);
}

.button_main_contact span {
  padding: 5px;
  position: relative;
  top: -10px;
  font-family: "eurostile", sans-serif;
  font-size: 18px;
  color: black;
}

.button_main_contact > a {
  text-decoration: none;
}

@media (max-width: 700px) {
  .button_main_contact {
    right: -50px;
  }
  .button_main_contact span {
    font-size: 14px;
  }
  .button_main_contact svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}/*# sourceMappingURL=Contact.css.map */