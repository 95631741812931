@import "../styles/Variables/Variables.scss";

*,
*::before,
*::after {
  box-sizing: inherit;
}

.contact_page {
  height: 135vh;
  position: relative;
  width: 100%;
  background-color: transparent;
  top: 9rem;
}

#contact-form ul li.half:first-child {
  margin-left: 0;
}

.contact_info {
  width: 50vw;
  padding-top: 5rem;
  display: block;
  position: relative;
  left: 6rem;
}

.contact_page > h1 {
  @include section;
  color: #47430b;
}

.contact_page > h1::before {
  @include h2TagOpen;
}

.contact_page > h1::after {
  @include h2TagClose;
}

.tags {
  color: $htmlcolortags;
  font-style: italic;
  font-weight: 500;
  left: 11rem;
  position: absolute;
  font-size: 1.3rem;
  font-family: $tags-Font;
}

.contact_underline {
  @include underline;
  background: #ff5708;
}

.contact_dot {
  @include dot;
  background: #ff5708;
  left: 27rem;
  bottom: 2rem;
}

@media screen and (max-width: 1000px) {
  .contact_page {
    height: 98vh;
  }

  #contact-form {
    width: 75% !important;
  }

  .contact_info {
    left: 3rem;
    padding-top: 0 !important;
  }

  #contact-form button {
    width: 12rem !important;
    left: 3.5rem !important;
    height: 4vh !important;
  }

  #contact-form::before {
    left: 0rem !important;
  }

  #contact-form::after {
    left: 0rem !important;
  }

  .contact_page > h1 {
    left: 5rem;
  }

  .contact_page > h1::before {
    left: -2rem;
  }

  .contact_page > h1::after {
    left: -2rem;
  }

  .contact_dot {
    left: 19.5rem;
  }

  .contact_underline {
    left: 5.5rem;
  }
}

@media screen and (max-width: 480px) {
  .contact_page {
    height: 160vh;
  }

  .contact_info {
    width: 81vw;
    left: 1rem;
  }

  .contact_underline {
    left: 2.5rem;
  }

  .contact_dot {
    height: 9px;
    width: 9px;
    left: 10.5rem;
    bottom: 1.5rem;
  }

  #contact-form {
    width: 100% !important;
  }

  #contact-form::before {
    left: 0rem !important;
    font-size: 1rem !important;
    bottom: 16rem !important;
  }

  #contact-form::after {
    bottom: -2rem !important;
    left: 0rem !important;
    font-size: 1rem !important;
  }

  #contact-form button {
    width: 10rem !important;
    left: 4rem !important;
  }

  .contact_page > h1 {
    font-size: 2.2rem;
    left: 2rem;
  }

  .contact_page > h1::before {
    font-size: 1rem;
    left: -1rem;
    bottom: 2.5rem;
  }

  .contact_page > h1::after {
    font-size: 1rem;
    left: -1rem;
    top: 4rem;
  }
}
