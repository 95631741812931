@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
*,
*::before,
*::after {
  box-sizing: inherit;
}

.my-work {
  position: relative;
  min-height: 100vh;
  height: 620vh;
  top: 10rem;
}

.my-works {
  width: 100%;
}

.my-work h1 {
  font-size: 4.8rem;
  position: relative;
  color: #024463;
  text-align: justify;
  font-family: "eurostile", sans-serif;
  left: 6rem;
}

.my-work h1::before {
  content: "<h2>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  font-size: 1.25rem;
  bottom: 4rem;
  position: absolute;
}

.my-work h1::after {
  content: "</h2>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: 6rem;
}

.work_underline {
  content: "";
  position: relative;
  left: 12rem;
  height: 4px;
  display: block;
  margin-top: 1px;
  width: 3.5rem;
  bottom: 0.5rem;
  background: #d25380;
  left: 7rem;
  width: 6.5rem;
}

.work_dot {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background: #d25380;
  bottom: 2rem;
  left: 20rem;
}

.works-subheader {
  position: relative;
  line-height: 1.5;
  top: 7rem;
  width: 70%;
  left: 12rem;
  font-size: 1.3rem;
  text-align: left;
  font-family: "eurostile", sans-serif;
}

.works-subheader > h3 {
  color: #024463;
  margin-bottom: 1.5rem;
  padding-bottom: 3rem;
  font-family: "eurostile", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.works-subheader > h3::before {
  content: "<h3>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: -1.5rem;
  font-size: 1.25rem;
  position: absolute;
}

.works-subheader > h3::after {
  content: "</h3>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: 5rem;
}

.scroll-animation-work {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 8rem;
  color: #1c494c;
  font-family: "eurostile", sans-serif;
  font-weight: 100;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.scroll-animation-work > span {
  padding: 5px;
}

.vertical_line-work {
  content: "";
  height: 200px;
  width: 0.5px;
  padding-top: 2px;
  position: relative;
  display: block;
  background-color: #1c494c;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(20px);
  }
}
.all-works-header {
  color: #024463;
  font-family: "eurostile", sans-serif;
  text-align: left;
  position: relative;
  top: 20rem;
  left: 2rem;
  width: 50%;
}

.all-works-header::before {
  content: "<h3>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: -1.5rem;
  font-size: 1.25rem;
  position: absolute;
  left: -10px;
  top: -2.5rem;
}

.all-works-header::after {
  content: "</h3>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: -1rem;
  top: 4rem;
}

.other-works-header {
  color: #024463;
  font-family: "eurostile", sans-serif;
  text-align: left;
  position: relative;
  top: 35rem;
  left: 2rem;
  width: 50%;
}

.other-works-header::before {
  content: "<h3>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: -1.5rem;
  font-size: 1.25rem;
  position: absolute;
  left: -10px;
  top: -2.5rem;
}

.other-works-header::after {
  content: "</h3>";
  font-family: "La Belle Aurore", cursive;
  font-weight: 100;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: -1rem;
  top: 4rem;
}

.other-works-header > h2 {
  font-size: 2rem;
}

.all-works-header > h2 {
  font-size: 2rem;
}

.all-work-underline {
  content: "";
  position: relative;
  left: 12rem;
  height: 4px;
  display: block;
  margin-top: 1px;
  width: 3.5rem;
  background-color: #d25380;
  left: 0rem;
  width: 5.5rem;
}

.other-work-dot {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background-color: #d25380;
  left: 11rem;
  height: 8px;
  width: 8px;
  bottom: 1.1rem;
}

.all-work-dot {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background-color: #d25380;
  left: 14rem;
  height: 8px;
  width: 8px;
  bottom: 1.3rem;
}

.all-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 33rem;
  transition: all 0.6s ease;
}

.work-left {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 14rem;
}

.reverse {
  flex-direction: row-reverse;
}

.work-left-img {
  align-items: center;
  display: flex;
  width: 55%;
  justify-content: center;
}

.work-left-img-mdm {
  align-items: center;
  display: flex;
  width: 55%;
  justify-content: center;
}

.work-left-img-portfolio {
  align-items: center;
  display: flex;
  width: 55%;
  justify-content: center;
}

.work-left-img-mood {
  align-items: center;
  display: flex;
  width: 55%;
  justify-content: center;
}

.work-left-img-spotify {
  align-items: center;
  display: flex;
  width: 55%;
  justify-content: center;
}

.work-left-img::before {
  content: "<img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: -8rem;
}

.work-left-img-mdm::before {
  content: "<img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: -8rem;
}

.work-left-img::after {
  content: "</img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: 20rem;
}

.work-left-img-mdm::after {
  content: "</img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: 20rem;
}

.work-left-img-portfolio::before {
  content: "<img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: -4rem;
}

.work-left-img-portfolio::after {
  content: "</img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: 22rem;
}

.work-left-img-mood::before {
  content: "<img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: -4rem;
}

.work-left-img-mood::after {
  content: "</img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: 22rem;
}

.work-left-img-spotify::before {
  content: "<img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: -4rem;
}

.work-left-img-spotify::after {
  content: "</img>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  left: 4rem;
  top: 22rem;
}

.work-left-img > img {
  width: 600px;
  border-radius: 15px;
  box-shadow: 0 60px 123px -25px rgba(44, 44, 45, 0.42), 0 35px 75px -35px hsla(225, 2%, 62%, 0.08);
  filter: blur(0);
  opacity: 1;
  transform: perspective(800px) rotateY(25deg) scale(0.8) rotateX(10deg);
  transition: all 0.6s ease;
}

.work-left-img-mdm > img {
  width: 600px;
  border-radius: 15px;
  box-shadow: 0 60px 123px -25px rgba(44, 44, 45, 0.42), 0 35px 75px -35px hsla(225, 2%, 62%, 0.08);
  filter: blur(0);
  opacity: 1;
  transform: perspective(800px) rotateY(25deg) scale(0.8) rotateX(10deg);
  transition: all 0.6s ease;
}

.work-left-img > img:hover {
  box-shadow: 0 19px 67px -38px rgba(59, 59, 60, 0.8);
  filter: blur(0.2px);
  opacity: 1;
  transform: perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1.1);
}

.work-left-img-mdm > img:hover {
  box-shadow: 0 19px 67px -38px rgba(59, 59, 60, 0.8);
  filter: blur(0.2px);
  opacity: 1;
  transform: perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1.1);
}

.work-left-img-portfolio > img {
  width: 600px;
  border-radius: 15px;
  box-shadow: 0 60px 123px -25px rgba(44, 44, 45, 0.42), 0 35px 75px -35px hsla(225, 2%, 62%, 0.08);
  filter: blur(0);
  opacity: 1;
  transform: perspective(800px) rotateY(25deg) scale(0.8) rotateX(10deg);
  transition: all 0.6s ease;
}

.work-left-img-portfolio > img:hover {
  box-shadow: 0 19px 67px -38px rgba(59, 59, 60, 0.8);
  filter: blur(0.2px);
  opacity: 1;
  transform: perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1.1);
}

.work-left-img-mood > img {
  width: 600px;
  border-radius: 15px;
  box-shadow: 0 60px 123px -25px rgba(44, 44, 45, 0.42), 0 35px 75px -35px hsla(225, 2%, 62%, 0.08);
  filter: blur(0);
  opacity: 1;
  transform: perspective(800px) rotateY(25deg) scale(0.8) rotateX(10deg);
  transition: all 0.6s ease;
}

.work-left-img-mood > img:hover {
  box-shadow: 0 19px 67px -38px rgba(59, 59, 60, 0.8);
  filter: blur(0.2px);
  opacity: 1;
  transform: perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1.1);
}

.work-left-img-spotify > img {
  width: 600px;
  border-radius: 15px;
  box-shadow: 0 60px 123px -25px rgba(44, 44, 45, 0.42), 0 35px 75px -35px hsla(225, 2%, 62%, 0.08);
  filter: blur(0);
  opacity: 1;
  transform: perspective(800px) rotateY(25deg) scale(0.8) rotateX(10deg);
  transition: all 0.6s ease;
}

.work-left-img-spotify > img:hover {
  box-shadow: 0 19px 67px -38px rgba(59, 59, 60, 0.8);
  filter: blur(0.2px);
  opacity: 1;
  transform: perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1.1);
}

.work-left-detail {
  display: flex;
  width: 45%;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #ddeff5;
  left: 2px;
}

.work-left-detail-mdm {
  display: flex;
  width: 45%;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #ddeff5;
  left: 2px;
}

.work-left-detail-portfolio {
  display: flex;
  width: 45%;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #ddeff5;
  left: 2px;
}

.work-left-detail-mood {
  display: flex;
  width: 45%;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #ddeff5;
  left: 2px;
}

.work-left-detail-spotify {
  display: flex;
  width: 45%;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #ddeff5;
  left: 2px;
}

.work-left-detail::before {
  content: "<div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: -3rem;
  left: 0.4rem;
}

.work-left-detail::after {
  content: "</div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: 26rem;
  left: 0.4rem;
}

.work-left-detail-mdm::before {
  content: "<div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: -3rem;
  left: 0.4rem;
}

.work-left-detail-mdm::after {
  content: "</div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: 26rem;
  left: 0.4rem;
}

.work-left-detail-portfolio::before {
  content: "<div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: -3rem;
  left: 0.4rem;
}

.work-left-detail-portfolio::after {
  content: "</div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: 26rem;
  left: 0.4rem;
}

.work-left-detail-mood::before {
  content: "<div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: -3rem;
  left: 0.4rem;
}

.work-left-detail-mood::after {
  content: "</div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: 26rem;
  left: 0.4rem;
}

.work-left-detail-spotify::before {
  content: "<div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: -3rem;
  left: 0.4rem;
}

.work-left-detail-spotify::after {
  content: "</div>";
  font-family: "La Belle Aurore", cursive;
  color: rgb(140, 137, 137);
  left: -4rem;
  top: 5rem;
  font-size: 1.25rem;
  position: absolute;
  top: 26rem;
  left: 0.4rem;
}

.work-left-detail > h4 {
  font-size: 1.5rem;
  position: relative;
  color: #024463;
  text-align: justify;
  font-family: "eurostile", sans-serif;
  margin-bottom: 2rem;
}

.work-left-detail-mdm > h4 {
  font-size: 1.5rem;
  position: relative;
  color: #024463;
  text-align: justify;
  font-family: "eurostile", sans-serif;
  margin-bottom: 2rem;
}

.work-left-detail-portfolio > h4 {
  font-size: 1.5rem;
  position: relative;
  color: #024463;
  text-align: justify;
  font-family: "eurostile", sans-serif;
  margin-bottom: 2rem;
}

.work-left-detail-mood > h4 {
  font-size: 1.5rem;
  position: relative;
  color: #024463;
  text-align: justify;
  font-family: "eurostile", sans-serif;
  margin-bottom: 2rem;
}

.work-left-detail-spotify > h4 {
  font-size: 1.5rem;
  position: relative;
  color: #024463;
  text-align: justify;
  font-family: "eurostile", sans-serif;
  margin-bottom: 2rem;
}

.work-left-detail h5 {
  position: relative;
  bottom: 1rem;
  color: #024463;
  font-family: "Bitter", serif;
  margin-bottom: 2rem;
}

.work-left-detail-mdm h5 {
  position: relative;
  bottom: 1rem;
  color: #024463;
  font-family: "Bitter", serif;
  margin-bottom: 2rem;
}

.work-left-detail-portfolio h5 {
  position: relative;
  bottom: 1rem;
  color: #024463;
  font-family: "Bitter", serif;
  margin-bottom: 2rem;
}

.work-left-detail-mood h5 {
  position: relative;
  bottom: 1rem;
  color: #024463;
  font-family: "Bitter", serif;
  margin-bottom: 2rem;
}

.work-left-detail-spotify h5 {
  position: relative;
  bottom: 1rem;
  color: #024463;
  font-family: "Bitter", serif;
  margin-bottom: 2rem;
}

.work-detail-dot {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background: #d25380;
  bottom: 2.8rem;
  left: 8.5rem;
  height: 8px;
  width: 8px;
}

.work-left-detail > p {
  color: #024463;
  font-family: "eurostile", sans-serif;
  font-weight: 600;
  text-align: center;
  margin: 0 0 3rem;
  line-height: 24px;
}

.work-left-detail-mdm > p {
  color: #024463;
  font-family: "eurostile", sans-serif;
  font-weight: 600;
  text-align: center;
  margin: 0 0 3rem;
  line-height: 24px;
}

.work-left-detail-portfolio > p {
  color: #024463;
  font-family: "eurostile", sans-serif;
  font-weight: 600;
  text-align: center;
  margin: 0 0 3rem;
  line-height: 24px;
}

.work-left-detail-mood > p {
  color: #024463;
  font-family: "eurostile", sans-serif;
  font-weight: 600;
  text-align: center;
  margin: 0 0 3rem;
  line-height: 24px;
}

.work-left-detail-spotify > p {
  color: #024463;
  font-family: "eurostile", sans-serif;
  font-weight: 600;
  text-align: center;
  margin: 0 0 3rem;
  line-height: 24px;
}

.work-left-detail a {
  width: 10rem;
}

.work-left-detail-mdm a {
  width: 10rem;
}

.work-left-detail-portfolio a {
  width: 10rem;
}

.work-left-detail-mood a {
  width: 10rem;
}

.work-left-detail-spotify a {
  width: 10rem;
}

.work-left-detail a:hover {
  transition: all 0.3s ease-in-out;
  transform: translateY(-6px);
}

.work-left-detail-mdm a:hover {
  transition: all 0.3s ease-in-out;
  transform: translateY(-6px);
}

.work-left-detail-portfolio a:hover {
  transition: all 0.3s ease-in-out;
  transform: translateY(-6px);
}

.work-left-detail-mood a:hover {
  transition: all 0.3s ease-in-out;
  transform: translateY(-6px);
}

.work-left-detail-spotify a:hover {
  transition: all 0.3s ease-in-out;
  transform: translateY(-6px);
}

.work-detail-dot-infa {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background: #d25380;
  bottom: 2.8rem;
  left: 7.9rem;
  height: 8px;
  width: 8px;
}

.work-detail-dot-infa-mdm {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background: #d25380;
  bottom: 2.8rem;
  left: 10.9rem;
  height: 8px;
  width: 8px;
}

.work-detail-dot-walmart {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background-color: #d25380;
  bottom: 2.8rem;
  left: 5.6rem;
  height: 8px;
  width: 8px;
}

.work-tech {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  position: relative;
  right: 20px;
  margin: 1rem 0 2rem;
}

.work-tech li {
  color: #024463;
  font-family: "Bitter", serif;
  white-space: nowrap;
  margin: 0 0 5px 20px;
  font-size: 13px;
  font-weight: bold;
}

.other-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 45rem;
  transition: all 0.6s ease;
}

.work-other-dot-mood {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background-color: #d25380;
  left: 7.2rem;
  bottom: 2.7rem;
  height: 8px;
  width: 8px;
}

.work-other-dot-portfolio {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background-color: #d25380;
  left: 7.2rem;
  bottom: 2.7rem;
  height: 8px;
  width: 8px;
}

.work-other-dot-profile {
  content: ".";
  font-family: "Bitter", serif;
  border-radius: 8px;
  height: 13px;
  width: 13px;
  display: block;
  position: relative;
  bottom: 1.6rem;
  background-color: #d25380;
  left: 4.5rem;
  bottom: 2.7rem;
  height: 8px;
  width: 8px;
}

@media screen and (max-width: 512px) {
  .my-work {
    height: 1100vh !important;
  }
  .my-works h1 {
    font-size: 2.2rem !important;
    left: 1rem !important;
  }
  .my-works h1::before {
    left: 0 !important;
    font-size: 1rem !important;
    bottom: 2.5rem !important;
  }
  .my-works h1::after {
    left: 0 !important;
    font-size: 1rem !important;
    top: 3rem !important;
  }
  .work_underline {
    width: 2.5rem !important;
    bottom: 0 !important;
    left: 1.2rem !important;
  }
  .work_dot {
    height: 6px;
    width: 6px;
    left: 7.5rem;
    bottom: 1rem;
  }
  .works-subheader {
    left: 3rem;
    font-size: 1rem;
  }
  .works-subheader > h3::before {
    font-size: 1rem !important;
    left: -2rem !important;
  }
  .works-subheader > h3::after {
    font-size: 1rem !important;
    top: 14rem !important;
    left: -2rem !important;
  }
  .all-works-header > h2 {
    font-size: 1.5rem;
  }
  .all-works-header::before {
    font-size: 1rem !important;
  }
  .all-works-header::after {
    font-size: 1rem !important;
    top: 3rem !important;
  }
  .all-work-dot {
    left: 10.5rem;
    bottom: 1.1rem;
  }
  .all-work-underline {
    width: 4.5rem;
  }
  .all-works {
    top: 30rem;
  }
  .work-left {
    flex-direction: column;
  }
  .work-left-img {
    width: 100%;
  }
  .work-left-img-mdm {
    width: 100%;
  }
  .work-left-img > img {
    width: 300px !important;
    position: relative !important;
    bottom: 4rem !important;
  }
  .work-left-img-mdm > img {
    width: 300px !important;
    position: relative !important;
    bottom: 4rem !important;
  }
  .work-left-img::before {
    font-size: 1rem !important;
    left: 3rem !important;
    top: -6rem !important;
  }
  .work-left-img-mdm::before {
    font-size: 1rem !important;
    left: 3rem !important;
    top: -6rem !important;
  }
  .work-left-img::after {
    font-size: 1rem !important;
    left: 3rem !important;
    top: 4rem !important;
  }
  .work-left-img-mdm::after {
    font-size: 1rem !important;
    left: 3rem !important;
    top: 6rem !important;
  }
  .work-left-detail {
    width: 80%;
    top: 2rem;
  }
  .work-left-detail-mdm {
    width: 80%;
    top: 2rem;
  }
  .work-left-detail-spotify {
    width: 80%;
    top: 2rem;
  }
  .work-left-detail::before {
    font-size: 1rem !important;
  }
  .work-left-detail-mdm::before {
    font-size: 1rem !important;
  }
  .work-left-detail-spotify::before {
    font-size: 1rem !important;
  }
  .work-detail-dot {
    left: 7.5rem;
    bottom: 2.7rem;
  }
  .work-left-detail > h4 {
    font-size: 1.3rem;
  }
  .work-left-detail-mdm > h4 {
    font-size: 1.1rem;
  }
  .work-left-detail-spotify > h4 {
    font-size: 1.1rem;
  }
  .work-left-detail > p {
    font-size: 0.9rem;
  }
  .work-left-detail-mdm > p {
    font-size: 0.9rem;
  }
  .work-left-detail-spotify > p {
    font-size: 0.9rem;
  }
  .work-tech {
    justify-content: center;
  }
  .work-left-detail::after {
    top: 30rem !important;
    font-size: 1rem !important;
  }
  .work-left-detail-mdm::after {
    top: 31rem !important;
    font-size: 1rem !important;
  }
  .work-left-detail-spotify::after {
    top: 30rem !important;
    font-size: 1rem !important;
  }
  .work-detail-dot-infa {
    left: 7rem !important;
  }
  .work-detail-dot-infa-mdm {
    left: 7.9rem;
    bottom: 2.6rem;
  }
  .work-left-img-portfolio {
    width: 100%;
  }
  .work-left-img-mood {
    width: 100%;
  }
  .work-left-img-spotify {
    width: 100%;
  }
  .work-left-img-portfolio > img {
    width: 300px !important;
    position: relative;
    bottom: 4rem;
  }
  .work-left-img-mood > img {
    width: 300px !important;
    position: relative !important;
    bottom: 4rem !important;
  }
  .work-left-img-spotify > img {
    width: 300px !important;
    position: relative !important;
    bottom: 4rem !important;
  }
  .work-left-detail-portfolio {
    width: 100% !important;
  }
  .work-left-detail-mood {
    width: 100% !important;
  }
  .work-left-detail-spotify {
    width: 100% !important;
  }
  .work-left-detail-portfolio::before {
    left: 2.4rem !important;
    top: -2rem !important;
    font-size: 1rem !important;
  }
  .work-left-detail-portfolio::after {
    left: 2.4rem !important;
    top: 21rem !important;
    font-size: 1rem !important;
  }
  .work-left-detail-mood::before {
    left: 2.4rem !important;
    top: -2rem !important;
    font-size: 1rem !important;
  }
  .work-left-detail-mood::after {
    left: 2.4rem !important;
    top: 23rem !important;
    font-size: 1rem !important;
  }
  .work-left-detail-spotify::before {
    left: 2.4rem !important;
    top: -2rem !important;
    font-size: 1rem !important;
  }
  .work-left-detail-spotify::after {
    left: 2.4rem !important;
    top: 23rem !important;
    font-size: 1rem !important;
  }
  .work-left-img-portfolio::before {
    font-size: 1rem !important;
    left: 3rem !important;
    top: -6rem !important;
  }
  .work-left-img-portfolio::after {
    font-size: 1rem !important;
    left: 3rem !important;
    top: 4rem !important;
  }
  .work-left-img-spotify::before {
    font-size: 1rem !important;
    left: 3rem !important;
    top: -6rem !important;
  }
  .work-left-img-spotify::after {
    font-size: 1rem !important;
    left: 3rem !important;
    top: 5rem !important;
  }
  .work-left-img-mood::before {
    font-size: 1rem !important;
    left: 3rem !important;
    top: -6rem !important;
  }
  .work-left-img-mood::after {
    font-size: 1rem !important;
    left: 3rem !important;
    top: 6rem !important;
  }
  .other-works-header > h2 {
    font-size: 1.6rem;
  }
  .other-work-dot {
    left: 9rem;
  }
  .other-works-header::before {
    font-size: 1rem;
  }
  .other-works-header::after {
    font-size: 1rem;
    top: 3rem;
  }
  .work-other-dot-profile {
    left: 3.5rem;
  }
}
@media screen and (max-width: 1000px) {
  .my-work {
    height: 520vh;
  }
  .works-subheader > h3::after {
    top: 12rem;
  }
  .work-left-img > img {
    width: 400px;
  }
  .work-left-detail::after {
    top: 30rem;
  }
  .work-left-img-mdm > img {
    width: 400px;
  }
  .work-left-detail-mdm > h4 {
    font-size: 1.3rem;
  }
  .work-left-detail-mdm::after {
    top: 31rem;
  }
  .work-detail-dot-infa-mdm {
    left: 9.9rem;
  }
  .work-left-img-portfolio > img {
    width: 400px;
  }
  .work-left-img-portfolio::after {
    top: 15rem;
  }
  .work-left-detail-portfolio::after {
    top: 22rem;
  }
  .work-left-img-mood > img {
    width: 400px;
  }
  .work-left-img-mood::after {
    top: 17rem;
  }
  .work-left-detail-mood > p,
  .work-left-detail-portfolio > p,
  .work-left-detail-spotify > p {
    font-weight: 400 !important;
  }
  .work-left-img-spotify > img {
    width: 400px;
  }
  .work-left-img-spotify::after {
    top: 17rem;
  }
}
@media screen and (max-width: 480px) {
  .my-work {
    height: 820vh !important;
  }
  .work_dot {
    left: 8rem !important;
    bottom: 1.1rem !important;
  }
  .all-work-dot {
    left: 11.5rem !important;
  }
  .work-detail-dot {
    left: 8.5rem !important;
  }
  .work-left-detail > p {
    font-weight: 400 !important;
  }
  .work-detail-dot-infa {
    left: 8rem !important;
  }
  .work-left-detail-mdm > h4 {
    font-size: 1rem !important;
  }
  .work-detail-dot-infa-mdm {
    left: 7.9rem !important;
    bottom: 2.7rem;
  }
  .work-left-detail-mdm > p {
    font-weight: 400 !important;
  }
  .other-work-dot {
    left: 10rem !important;
  }
  .work-other-dot-portfolio {
    left: 8.2rem !important;
  }
  .work-left-detail-mood > p {
    font-weight: 400 !important;
    font-size: 0.9rem !important;
  }
  .work-other-dot-profile {
    left: 4.5rem !important;
  }
  .work-other-dot-mood {
    left: 8.2rem !important;
  }
}/*# sourceMappingURL=MyWork.css.map */