@use 'sass:math';
@import "../styles/Variables/Variables.scss";


*,
*::before,
*::after {
    box-sizing: inherit;
}

$background--color: #1e2023;
$icon--color: #1e2023;
$font--color: black;
$font--color--active: #000000;
$font--primary: 'Fira Sans', sans-serif;
$transition--length: .8;
$nav--theme: var(--background-color);

.menu-icon {
    $size: 30px;
    height: $size;
    width: $size;
    position: fixed;
    z-index: 2;
    left: 50px;
    top: 40px;
    cursor: pointer;

    &__line {
        height: 2px;
        width: $size;
        display: block;
        background-color: $font--color;
        margin-bottom: 4px;
        transition: transform .2s ease, background-color .5s ease;
    }

    &__line-left {
        width: math.div($size ,2);
    }

    &__line-right {
        width: math.div($size , 2);
        float: right;
    }

    & .active {
                &__line {
                    background-color: #000;
                    transform: translateX(0px) rotate(-45deg);
                }
        
                &__line-left {
                    transform: translateX(1px) rotate(45deg);
                }
        
                &__line-right {
                    transform: translateX(-2px) rotate(45deg);
                }
    }
}

.nav {
    $width: 97vw;
    $height: 100vh;
    $font--size--calc: calc(2vw + 10px);
    $transition--easing: cubic-bezier(.77, 0, .175, 1);
    position: fixed;
    z-index: 1;
    visibility: hidden;
    

    &.open {
visibility: visible;

    &:before,
    &:after {
        transform: translateX(0%) translateY(0%);
    }

    &:after {
        transition-delay: .1s;
    }

    &:before {
        transition-delay: 0s;
    }

    & .nav--list-item {
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 1.3s ease, transform 1.3s ease, color 1.3s ease;

        @for $i from 0 through 4 {
            &:nth-child(#{$i}) {
                transition-delay: math.div($transition--length * $i,8) + .9 + s;
                text-decoration: none;
                color:black
            }
        }
    }
    & .social-links {
        opacity: 1;
            transform: translateX(0%);
            transition: opacity .3s ease, transform .3s ease, color .3s ease;
            @for $i from 0 through 1 {
                    &:nth-child(#{$i}) {
                        transition-delay: math.div($transition--length * $i, 8) + .5 + s;
                        text-decoration: none;
                        color: black;
                    }
                }
    }
    & .desc_link_item {
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 1.3s ease, transform 1.3s ease, color 1.3s ease;

        @for $i from 0 through 4 {
            &:nth-child(#{$i}) {
                transition-delay: math.div($transition--length * $i,8) + .9 + s;
                text-decoration: none;
                color:black
            }
        }
    }

    }

    &:before,
    &:after {
        content: "";
        position: fixed;
        width: $width;
        height: $height;
        background: rgba(#eaeaea, .2);
        z-index: -1;
        transition: transform $transition--easing $transition--length + s;
        transform: translateX(0%) translateY(-110%);
    }

    &:after {
        background: $nav--theme;
        transition-delay: 0s;
    }

    &:before {
        transition-delay: .1s;
    }

    &__content {
        position: fixed;
        top: 50%;
        transform: translate(0%, -50%);
        width: 100%;
        text-align: center;
        font-size: $font--size--calc;
        font-weight: 200;
        cursor: pointer;
    }

    & .nav--list-item {
            position: relative;
            display: inline-block;
            transition-delay: $transition--length + s;
            opacity: 0;
            transform: translate(0%, 100%);
            transition: opacity .2s ease, transform .3s ease;
            margin-right: 25px;
    
            &:before {
                content: "";
                position: absolute;
                background: $font--color--active;
                width: 20px;
                height: 1px;
                top: 100%;
                transform: translate(0%, 0%);
                transition: all .3s ease;
                z-index: -1;
            }
    
            &:hover {
                &:before {
                    width: 100%;
                }
            }
        }

    
}

.nav--list-item {
    position: relative;
    display: inline-block;
    transition-delay: $transition--length + s;
    opacity: 0;
    font-size: 3rem;
    font-weight: 700;
    font-family: $content-fonts;
    transform: translate(0%, 100%);
    color: #070d55;
    transition: opacity .2s ease, transform .3s ease;
    margin-right: 25px;

    &:before {
        content: "";
        position: absolute;
        background: $font--color--active;
        width: 20px;
        height: 1.5px;
        top: 100%;
        transform: translate(0%, 0%);
        transition: all .3s ease;
        z-index: -1;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}

.social-links{
position: relative;
text-align: left;
bottom: 5rem;
left: 2rem;
margin: 0 0 30px;
padding-left: 100px;
z-index: 1;
list-style: none;
opacity: 0;
transform: translate(0%, 100%);
    transition: opacity .2s ease, transform .3s ease;
}

.social-links svg {
    font-size: 2rem;
}

.social-links::before{
    content: '';
    position: absolute;
    height: 3px;
    width: 80px;
    background-color: #7f00e0 ;
    top:63%;
    left: 0;
}

.social-links li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 50px 0 0 ;
}

.social-links li svg {
    color: black;
}

.social-links li svg:hover {
    color: white
}

.nav__list{
    display: flex;
        flex-direction: column;
        gap: 3rem;
        align-items: flex-start;
        padding: 2rem;
}


@media screen and (max-width: 512px){
    .nav--list-item{
        font-size: 2.5rem !important;
    }

    .nav::after{
        width: 89vw !important;
    }

    .social-links{
        bottom: 0rem !important;
    }

    .social-links li svg{
        width: 1em !important;
        height: 1em !important;
    }
}

@media screen and (max-width: 512px) {
    .desc_link_item{
           display: none;
    }
}

@media screen and (max-width: 1000px){
   .nav--list-item{
     font-size: 5rem;
   }

   .nav::after{
    width: 96vw;
   }

   .social-links li svg {
    height: 4rem;
    width: 10rem;;
   }
}

.nav__list_desc{
    list-style-type: none;
    display: flex;
    align-items: center;
    width: 40rem;
}

.desc_link_item{
    font-size: 1.2rem;
    width: 80%;
    opacity: 0;
    transform: translate(0%, 100%);
    transition: opacity .2s ease, transform .3s ease;
}










