.App {
  text-align: center;
  background-color: white;
}

.app-none{
  display: none;
}

.page-wrap{
  min-height: calc(100vh - 40px);
  position: relative;
  background: #f4f9fc;
  overflow: hidden;
  margin: 20px;
  
}

.footer{
  display: block;
  position: relative;
  bottom:2.2rem;
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  color: #676a69;
  font-family: -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

.footer::before{
  content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    height: 1px;
    width: 7rem;
    background: #919795;
}


.footer::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  height: 1px;
  width: 7rem;
  background: #919795;
}

@media(max-width: 512px){
  .footer{
    font-size: 0.5rem;
  }
  .footer::before{
    width: 2rem;
  }
  .footer::after{
    width: 2rem;
  }
}







